<template >
    <v-footer dark padless >
        <v-container class="text-center pa-1 text-body-2">
            BaconHash © {{ year }} - <a href="https://pcaro.es">Pablo Caro</a>
        </v-container>
    </v-footer>
</template>

<script>

export default {

    name: 'Footer',

    data() {
        return {
            "year": new Date().getFullYear()
        }
    },

    // mounted () {
    //     this.year = new Date().getFullYear();
    // },

}

</script>

<style>
</style>
