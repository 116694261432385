<template dark>

  <v-app>

    <Header/>

    <v-main>
      <router-view/>
    </v-main>

    <Footer/>

  </v-app>

</template>

<script>

  import Header from "./components/Header.vue"
  // import Search from "./components/Search.vue";
  // import What from "./components/What.vue";
  import Footer from "./components/Footer.vue";

  export default {
    components: {
      Header,
      // Search,
      // What,
      Footer
    }
  }
</script>
