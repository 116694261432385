<template dark>
    <v-container fluid>
        <v-form v-on:submit.prevent="search_hash()">
            <v-container>
                <v-row>
                    <v-col cols=7 offset=2>
                        <v-text-field autofocus solo required placeholder="Hash" v-model="hash_text" ></v-text-field>
                    </v-col>
                    <v-col cols=1>
                        <v-btn color=#ffd9b3 height=48px type=submit large dark block style="font-size: 30px">🥓</v-btn>
                    </v-col>
                </v-row>


            </v-container>
        </v-form>

        <v-container class=text-center>
            <v-row v-if=show_result>
                <v-col cols=8 offset=2>
                    <v-card outlined>

                        <v-card-title v-if=result_error class="justify-center mb-4">
                            {{ result_error }}
                        </v-card-title>
                        <v-card-title v-else-if=result_plain class="justify-center mb-4">
                            Hash found. Hooray!
                        </v-card-title>
                        <v-card-title v-else class="justify-center mb-4">
                            Hash not found...
                        </v-card-title>

                        <v-template v-if=result_plain>
                            <v-card-title class="justify-center">
                                <code>{{ result_alg }}</code>
                            </v-card-title>
                            <v-card-title class="justify-center">
                                <code>{{ result_plain }}</code>
                            </v-card-title>
                            <v-card-text v-if=result_plain class="text-caption">
                                (That means that if you use <code>{{ result_alg }}</code> to hash the string <code>{{ result_plain }}</code>, the result is <code>{{ hash_text }}</code>. But you probably knew that already.)
                            </v-card-text>
                        </v-template>

                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols=8 offset=2>
                    <v-card outlined>

                        <v-card-title class="justify-center mb-4">What is this?</v-card-title>
                        <v-card-text class="text-justify">
                            <p>Do you have MD5, NTLM or SHA1 hashes and you want to find the <b>plain text</b> that generates them, without spending time cracking? Then maybe <b>BaconHash</b> is for you! <b>BaconHash</b> is a lookup table for pre-computed MD5, NTLM and SHA1 hashes.</p>
                            <p>Currently, <b>BaconHash</b> has exactly 126062667085 entries (that's over one hundred and twenty-six billions, or around 1.26E11 if you're a scientist) for <b>every</b> algorithm (which makes a grand total of 378188001255 entries).</p>
                        </v-card-text>

                        <v-card-title class="justify-center mb-4">How do I use it?</v-card-title>
                        <v-card-text class="text-justify">
                            <p>You can use <b>BaconHash</b> in several ways:</p>
                            <ul>
                                <li>If you have a single hash you wish to search, just use the above text input.</li>
                                <li>If you have several hashes, you can use the <router-link to=/api>API</router-link> to programmatically make requests, and even search for several hashes at once.</li>
                                <li>You can also use the <a href=/bacon>bacon client</a>, which uses the API.</li>
                                <li>You can also use the <a href=telegram>Telegram bot</a> (you need a <router-link to=/BaconTicket>BaconTicket</router-link> for that).</li>
                            </ul><br>
                            <p>However, please have in mind that there is a rate limit by source IP. If you have a lot of hashes you want to look up, you need one of the following:</p>
                            <ul>
                                <li>A lot of patience.</li>
                                <li>A lot if IP addresses.</li>
                                <li>Getting a <router-link to=/BaconTicket>BaconTicket</router-link>.</li>
                            </ul>
                        </v-card-text>

                        <v-card-title class="justify-center mb-4">What's the correct format?</v-card-title>
                        <v-card-text class="text-justify">
                            <p><b>BaconHash</b> supports three hashing algorithms: MD5, NTLM (not NetNTLM, just the good old NT hashes) and SHA1. </p>
                            <ul>
                                <li>You can just search the hash and nothing else:</li>
                                <pre>
    #~ curl -s https://api.baconhash.pw/2707569be0aff4a956388a851c68b4c6
    {
      "hash": "2707569be0aff4a956388a851c68b4c6",
      "found": true,
      "type": "md5",
      "plain": "potato123"
    }
                                </pre>

                                <li>You can also send hashes associated with an username:</li>
                                <pre>
    #~ curl -s https://api.baconhash.pw/pcaro:2707569be0aff4a956388a851c68b4c6
    {
      "hash": "2707569be0aff4a956388a851c68b4c6",
      "found": true,
      "user": "pcaro",
      "type": "md5",
      "plain": "potato123"
    }
                                </pre>

                                <li>In case of NTLM hashes, you can even send the full domain dump hash, like this: </li>
                                <pre>
    #~ curl -s https://api.baconhash.pw/Administrator:500:aad3b435b51404eeaad3b435b51404ee:2000c92a544e63c3345c2b4a4d2379de:::
    {
      "hash": "2000c92a544e63c3345c2b4a4d2379de",
      "found": true,
      "type": "ntlm",
      "user": "Administrator",
      "plain": "potato123"
    }
                                </pre>

                                <li>It works even with a domain name: </li>
                                <pre>
    #~ curl -s https://api.baconhash.pw/AWESOME.DOMAIN\\Administrator:500:aad3b435b51404eeaad3b435b51404ee:2000c92a544e63c3345c2b4a4d2379de:::
    {
      "hash": "2000c92a544e63c3345c2b4a4d2379de",
      "found": true,
      "type": "ntlm",
      "domain": "AWESOME.DOMAIN",
      "user": "Administrator",
      "plain": "potato123"
    }
                                </pre>

                                <li>Also, LDAP {SHA} hashes are supported:</li>
                                <pre>
    #~ curl -s https://api.baconhash.pw/AWESOME.DOMAIN\\Administrator:500:aad3b435b51404eeaad3b435b51404ee:2000c92a544e63c3345c2b4a4d2379de:::
    {
      "hash": "2000c92a544e63c3345c2b4a4d2379de",
      "found": true,
      "type": "ntlm",
      "domain": "AWESOME.DOMAIN",
      "user": "Administrator",
      "plain": "potato123"
    }
                                </pre>
                            </ul>
                        </v-card-text>

                        <v-card-title class="justify-center mb-4">BaconHash didn't find my hash/doesn't support my algorithm/doesn't work!</v-card-title>
                        <v-card-text class="text-justify">
                            <p>Well, <b>BaconHash</b> is a <b>hobby project</b>, and it doesn't try to cover every hash algorithm in existence, and of course it doesn't store every password every user could possibly use. If you ever find the need to crack a very important hash, or you could use some other ad-hoc features in <b>BaconHash</b>, just <a href=mailto:me@pcaro.es>let me know</a>, and we'll see what we can do.</p>
                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>

        </v-container>

    </v-container>
</template>

<script>

import axios from "axios";

export default {
    name: "Search",

    data() {
        return {
            "result_error": "",
            "result_plain": "",
            "result_alg": "",

            "show_result": false,
        }
    },

    methods: {

        search_hash: function() {
            if (!this.hash_text) {
                return;
            }
            var url = "https://api.baconhash.pw/" + this.hash_text;
            axios
                .get(url)
                .then(response => this.check_response(response.data));
        },

        check_response: function(data) {
            console.log(data);

            this.show_result = true;
            this.result_error = data.error;
            this.result_plain = data.plain;
            this.result_alg = data.type;

        }

    },

    mounted() {
    }
}

</script>
